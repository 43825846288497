/*
 * @Description: 用户相关
 * @Date: 2019-12-25 11:07:47
 * @LastEditTime: 2020-08-17 11:31:07
 * @LastEditors: 矢车
 */
import axios from './axios'

// 用户审核列表(后台管理)
export const getUserList = params => {
  return axios.get('/api/operators/v2/audits/', { params })
}

// 审核用户
export const auditUser = (id, data) => {
  return axios.patch(`/api/operators/v2/audits/${id}/`, data)
}
// 提交  获取推荐人
export const postRecommendUser = () => {
  return axios.GET('/api/operators/v1/recommend-user/', { })
}
// 获取推荐人列表
export const RecommenduserList = (params) => {
  return axios.get('/api/operators/v1/recommend-user/', { params });
};

// 获取用户s详情
export const getUsers = (params) => {
  return axios.get(`/api/operators/v1/background-users/`, { params })
}

// 获取用户详情
export const getUser = (id, params) => {
  return axios.get(`/api/operators/v1/background-users/${id}/`, { params })
}

// 获取用户访问记录
export const getUserInterviewRecord = params => {
  return axios.get('/api/v1/plogs/', { params })
}

// 修改用户信息
export const editUserInfo = (id, data) => {
  return axios.patch(`/api/operators/v1/background-users/${id}/`, data)
}

// 新增收货地址
export const saveAddress = data => {
  return axios.post('/api/v1/addresses/', data)
}

// 修改收货地址
export const editAddress = (id, data) => {
  return axios.patch(`/api/v1/addresses/${id}/`, data)
}

// 获取收货地址列表
export const getAddressList = params => {
  return axios.get('/api/v1/addresses/', { params })
}

// 获取实名认证列表
export const getRealnameList = params => {
  return axios.get('/api/v1/identities/', { params })
}

// 实名认证审核
export const realnameApproval = (id, data) => {
  return axios.patch(`/api/v1/identities/${id}/`, data)
}

// 提现记录
export const getWithdrawList = params => {
  return axios.get('/api/operators/v1/money-records/', { params })
}

// 获取提现记录详情
export const getWithdrawRecordDetail = id => {
  return axios.get(`/api/operators/v1/money-records/${id}/`)
}

// 提现审批
export const approvalWithdrawApply = (id, data) => {
  return axios.patch(`/api/operators/v1/money-records/${id}/`, data)
}

// 获取oss上传token
export const getOssToken = () => {
  return axios.get('/api/v1/oss-token/')
}

// 上传提现业务截图
export const uploadOss = (host, formData) => {
  return axios.post(host, formData)
}

// 编辑前台用户权限
export const patchUserRight = (data) => {
  return axios.patch('/api/operators/v1/user/update-user-right/', data)
}

// 查询淘宝注册用户粉丝数
export const getTaobaoFans = data => {
  return axios.post(`/api/operators/v1/get-tbuser-fanscount/`, data)
}

// 抖音账号视频数据
export const getDouyinVideoData = params => {
  return axios.get(`/api/operators/v1/douyin-video-data/`, params)
}

// 抖音账号粉丝/互动数据
export const getDouyinFansandplayData = params => {
  return axios.get(`/api/operators/v1/douyin-fansandplay-data/`, params)
}

// 粉丝画像数据
export const getDouyinFansData = params => {
  return axios.get(`/api/operators/v1/douyin-fans-data/`, params)
}

// 白名单列表接口
export const getWhiteListData = params => {
  return axios.get(`/api/operators/v1/white-list/`, params)
}

// 新增白名单接口
export const postWhiteList = data => {
  return axios.post(`/api/operators/v1/white-list/`, data)
}

// 删除白名单接口
export const delWhiteList = id => {
  return axios.delete(`/api/operators/v1/white-list/${id}/`)
}

// 评级申请列表
export const getUserLevelList = params => {
  return axios.get(`/api/operators/v1/user-level/`, {
    params
  })
}

// 后台用户简略信息列表
export const getAdminUserBriefList = () => {
  return axios.get(`/api/operators/v1/admin-user-brief-list/`)
}

// 评级申请审核
export const postUserLevel = data => {
  return axios.post(`/api/operators/v1/user-level/`, data)
}

// 评级申请详情
export const getUserLevelDetail = id => {
  return axios.get(`/api/operators/v1/user-level/${id}/`)
}

// 淘宝信息重新查询
export const getQueryTaobaoAuth = params => {
  return axios.get(`/api/operators/v1/query-taobao-auth/`, { params })
}

// 用户解除绑定
export const patchUserUnbind = data => {
  return axios.patch(`/api/operators/v1/user-unbind/`, data)
}

// 抖音账户渠道信息
export const getDouyinChannelInfo = params => {
  return axios.get(`/api/operators/v1/douyin-channel-info/`, { params })
}

// 导出用户
export const getExportUser = params => {
  return axios.get(`/api/operators/v1/export-user/`, { params })
}
